import * as React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import scrollTo from 'gatsby-plugin-smoothscroll';

const Navbar = () => (
    <nav className="navbar navbar-dark navbar-expand-lg fixed-top bg-dark navbar-custom">
        <div className="container"><a className="navbar-brand" href="#">Bright-A-Bins</a><button data-toggle="collapse" className="navbar-toggler" data-target="#navbarResponsive"><span className="navbar-toggler-icon"></span></button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="nav navbar-nav ml-auto">
                    <li className="nav-item" role="presentation"><AnchorLink className="nav-link" to={"/#masthead"}>Home</AnchorLink></li>
                    <li className="nav-item" role="presentation"><AnchorLink className="nav-link" to={"/#services"} >Services</AnchorLink></li>
                    <li className="nav-item" role="presentation"><AnchorLink className="nav-link" to={"/#contact-us"}>Contact</AnchorLink></li>
                    <li className="nav-item" role="presentation"><AnchorLink className="nav-link" to={"/#prices"}>Prices</AnchorLink></li>
                </ul>
            </div>
        </div>
    </nav>
)

export default Navbar;