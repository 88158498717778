import * as React from 'react';
import LogoFacebook from 'react-ionicons/lib/LogoFacebook';
import LogoTwitter from 'react-ionicons/lib/LogoTwitter';
import LogoSnapchat from 'react-ionicons/lib/LogoSnapchat';
import LogoInstagram from 'react-ionicons/lib/LogoInstagram';


const Footer = () => (
    <div className="footer-dark">
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-3 item">
                        <h3>Services</h3>
                        <ul>
                            <li><a href="#">Wheelie Bin Cleaning</a></li>
                            <li><a href="#">Commercial Bin Cleaning</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-md-3 item">
                        <h3>About</h3>
                        <ul>
                            <li><a href="#">Company</a></li>
                            <li><a href="#">Team</a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 item text">
                        <address>
                            <strong>Bright-A-Bins</strong><br />
                            PO Box 111<br />
                            Ballarat, VIC 3350<br />
                            <abbr title="Phone">P:</abbr> (123) 456-7890<br />
                            <abbr title="Email">E:</abbr> <a href="mailto:#">brightabins@outlook.com</a>
                        </address>
                    </div>
                    <div className="col item social"><a href="#"><LogoFacebook className="icon" fontSize="20px" color="#fff" /></a><a href="#"><LogoTwitter className="icon" fontSize="20px"  color="#fff" /></a><a href="#"><LogoSnapchat className="icon" fontSize="20px"  color="#fff" /></a><a href="#"><LogoInstagram className="icon" fontSize="20px"  color="#fff" /></a></div>
                </div>
                <p className="copyright">Bright-A-Bins © 2020</p>
            </div>
        </footer>
    </div>
)

export default Footer;